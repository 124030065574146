import { components } from "~/openapi/schema";
import React from "react";
import { cn } from "~/lib/utils";
import { NavLink } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import * as Popover from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";
import { FadeNavbarItem } from "~/components/navbar/fade-navbar-item";

type NavbarDropdownNavItemProps = {
  dropdown: components["schemas"]["UiDropdownComponent"];
  delay: number;
};

export const NavbarDropdownNavItem: React.FC<NavbarDropdownNavItemProps> = ({
  dropdown,
  delay,
}) => {
  return (
    <Popover.Root>
      <Popover.Trigger
        className={"cursor-pointer outline-none focus-visible:outline-none"}
      >
        <FadeNavbarItem className={"flex items-center gap-2"} delay={delay}>
          <Button
            asChild
            variant={"link"}
            size={"default"}
            className={cn("text-lg")}
          >
            <div>
              {dropdown.rootText}
              <ChevronDown />
            </div>
          </Button>
        </FadeNavbarItem>
      </Popover.Trigger>

      <Popover.Content
        className={"ring-none border-none bg-foreground/60 p-1 outline-none"}
      >
        <ul className="flex flex-col gap-2 py-1">
          {dropdown.dropdownButtons?.map((item) => (
            <li key={item.text}>
              <Button
                asChild
                variant={item.variant}
                size={item.size}
                className={cn("text-lg")}
              >
                <NavLink to={item.link ?? ""}>
                  {item.text ?? "Unknown link"}
                </NavLink>
              </Button>
            </li>
          ))}
        </ul>

        <Popover.Arrow className={"fill-foreground/60"} />
      </Popover.Content>
    </Popover.Root>
  );
};
