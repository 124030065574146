import { components } from "~/openapi/schema";
import { Button } from "~/components/ui/button";
import { Link, NavLink } from "@remix-run/react";
import { cn } from "~/lib/utils";
import { StrapiRepository } from "~/openapi/strapiRepository";
import { useEffect, useState } from "react";
import { MenuIcon } from "~/components/icons/menuIcon";
import { navbarHeight } from "../../../tailwind.config";
import { NavbarDropdownNavItem } from "~/components/navbar/dropdown-item";
import { FadeNavbarItem } from "~/components/navbar/fade-navbar-item";

export const Navbar = ({
  isHomepageVariant,
  navbarItems,
}: {
  isHomepageVariant: boolean;
  navbarItems: components["schemas"]["Navbar"];
}) => {
  const { getImageUrl } = new StrapiRepository();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const [isScrolledOverHeroSection, setIsScrolledOverHeroSection] =
    useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolledOverHeroSection(
        window.scrollY > window.innerHeight - navbarHeight,
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={cn(
        "h-navbar-height sticky top-0 z-20 w-full border-b-[1px] border-border/20 backdrop-blur-md transition-all duration-500",
        {
          "bg-foreground/50": isHomepageVariant && isScrolledOverHeroSection,
          "border-border/80": !isHomepageVariant,
        },
      )}
    >
      <nav
        className={cn(
          "container mx-auto flex h-full w-full flex-wrap items-center justify-between p-4",
          {
            "text-foreground": !isHomepageVariant,
            "text-background": isHomepageVariant || isMobileMenuOpen,
          },
        )}
      >
        {navbarItems.logo && (
          <Link to={"/"}>
            <img
              className={"w-28 md:w-32 lg:w-40"}
              src={getImageUrl(navbarItems.logo)}
              alt={navbarItems.logo?.alternativeText}
            />
          </Link>
        )}

        <MenuIcon
          isOpen={isMobileMenuOpen}
          className={"relative z-20 cursor-pointer md:hidden"}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        />

        <div
          className={cn(
            "w-full md:flex md:w-auto md:items-center",
            {
              hidden: !isMobileMenuOpen,
            },
            {
              "pt-navbar-height absolute left-0 top-0 h-svh w-svw bg-foreground/90":
                isMobileMenuOpen,
            },
          )}
        >
          <ul
            className={"flex flex-col items-center gap-3 md:flex-row md:gap-4"}
          >
            {navbarItems.nav_items?.map((item, index) => {
              switch (item.__component) {
                case "ui.button":
                  return (
                    <li key={item.text} className={"flex items-center"}>
                      <Button
                        asChild
                        variant={item.variant}
                        size={item.size}
                        className={cn("text-lg")}
                      >
                        <NavLink to={item.link ?? ""} onClick={closeMobileMenu}>
                          <FadeNavbarItem delay={(index + 1) * 0.1}>
                            {item.text}
                          </FadeNavbarItem>
                        </NavLink>
                      </Button>
                    </li>
                  );
                case "ui.dropdown":
                  return (
                    <li key={item.id}>
                      <NavbarDropdownNavItem
                        dropdown={item}
                        delay={(index + 1) * 0.1}
                      />
                    </li>
                  );
              }
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};
