import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/button";

export default function ErrorPage({ errorMessage }: { errorMessage?: string }) {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mb-2 text-4xl font-bold">Ups...</h1>
      <p className={"mb-4"}>Zkuste to prosím později.</p>
      {errorMessage && (
        <div className={"flex gap-2"}>
          <span className={""}>Chyba:</span>
          <span className="text-red-500">{errorMessage}</span>
        </div>
      )}
      <Button asChild variant={"link"}>
        <Link to="/">Jít zpět na úvodní stránku</Link>
      </Button>
    </div>
  );
}
