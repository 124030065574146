import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { json, LinksFunction } from "@remix-run/node";

import "./tailwind.css";
import { ENV } from "~/env";
import NotFoundPage from "~/components/pages/errors/NotFoundPage";
import ErrorPage from "~/components/pages/errors/ErrorPage";
import StrapiErrorPage from "~/components/pages/errors/StrapiErrorPage";
import { Navbar } from "~/components/navbar/navbar";
import { StrapiRepository } from "~/openapi/strapiRepository";
import { TailwindIndicator } from "~/lib/tailwind-indicator";

export const loader = async () => {
  const strapiRepository = new StrapiRepository();
  const { getNavbarItems } = strapiRepository;
  const { data: navbarData } = await getNavbarItems();

  console.log("navbarData", navbarData);

  return json({
    navbarData,
    ENV,
  });
};

export const links: LinksFunction = () => [
  {
    rel: "icon",
    type: "image/x-icon",
    href: "/favicon.ico",
  },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        <Meta />
        <Links />
        <title>Vesas - Rodinná stavební firma z Třebíče</title>
      </head>
      <body className={"font-aeonik-pro"}>
        <div className={"mx-auto"}>{children}</div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.log("Error happened", error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundPage />;
    }
  }

  if (error instanceof Error) {
    if (error.message.includes("fetch failed")) {
      return <StrapiErrorPage />;
    }
    return <ErrorPage errorMessage={error.message} />;
  }

  captureRemixErrorBoundaryError(error);
  return <ErrorPage />;
};

export default function App() {
  const { navbarData, ENV } = useLoaderData<typeof loader>();
  const { pathname } = useLocation();

  const isHomepage = pathname === "/";

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(ENV)}`,
        }}
      />
      {navbarData?.data && (
        <Navbar isHomepageVariant={isHomepage} navbarItems={navbarData.data} />
      )}
      <Outlet />
      <TailwindIndicator />
    </>
  );
}
